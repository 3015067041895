import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../assets/img/website-logo7.png';

function Header() {
  const [expanded, setExpanded] = useState(false);
  const [clientIp, setClientIp] = useState(null);
  const location = useLocation(); // Hook to get the current location

  // Allowed IP addresses
  const allowedIpAddresses = ["97.86.190.181"]; // Replace with your list of allowed IP addresses

  // Fetch the client's IP address
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setClientIp(data.ip));
  }, []);

  // Collapse the menu when navigating to a new page
  useEffect(() => {
    setExpanded(false);
  }, [location]);

  return (
    <Navbar expanded={expanded} expand="lg" className="dark-pink-navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            width="250"
            height="85"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        >
          {expanded ? <span className="close-icon">&times;</span> : <span className="navbar-toggler-icon"></span>}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" onClick={() => setExpanded(false)}>
            {location.pathname !== "/" && <Nav.Link as={Link} to="/">Home</Nav.Link>}
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            <Nav.Link as={Link} to="/resources">Resources</Nav.Link>
            <Nav.Link as={Link} to="/servicesfaq">Services & FAQ</Nav.Link>
            <Nav.Link as={Link} to="/reviews">Reviews</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            {clientIp && allowedIpAddresses.includes(clientIp) && (
              <Nav.Link as={Link} to="/signdocuments">Sign Documents</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
