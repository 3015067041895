import React from 'react';
import './About.css'; // Make sure this path matches your CSS file's location
import profilePic from "../assets/img/profile-pic-1.jpg";

function About() {
  return (
    <div className="about-container">
      <div className="profile-pic-placeholder">
        {/* Placeholder for the profile picture. Replace src with your actual image path */}
        <img src= {profilePic} alt="Amanda Antony" />
      </div>
      <div className="content">
        <div className="paragraph">
          <p>Before becoming a married woman and mother, Amanda Antony spent over 6 years in religious life (i.e., convent), embarking on a spiritual and emotional journey that would fuel her life. After realizing that her true vocation was to find the man who would hold her in awe and exclaim, “At last, this one is bone of my bones, flesh of my flesh,” she left the convent in search of her beloved. She moved to Saint Louis and four years later, she met Benedict who was living in India searching for his “lost rib, tossed across oceans.” They were married in October 2017.</p>
        </div>
        <div className="paragraph">
          <p>In preparation for the arrival of their first child, Amanda searched for a doula who shared her spiritual and moral outlook on pregnancy, birth, and the pre-born. She found a doula (whom she loves) through word of mouth and realized the need for Catholic doulas in particular. She also developed a passion for a whole-person centered, evidence-based birth experience, one that engages the spiritual faculties through prayer. After a time of financial preparation, discernment, and training, she made the leap in her journey as a doula and birth coach in March 2024.</p>
        </div>
        <div className="paragraph">
          <p>Amanda looks forward to working with clients who desire to incorporate Catholic spirituality into their birth experience. Using both traditional modes of prayer and modern doula techniques, Amanda will help the laboring woman to approach labor with interior strength, confidence, and love.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
