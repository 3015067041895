import React, { useState, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useLocation } from 'react-router-dom';
import './InputPage.css';
import { ArrowLeft } from 'react-bootstrap-icons';

function PartnerPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { clientDetails = {}, partnerDetails = {} } = location.state || {};

    const [firstName, setFirstName] = useState(partnerDetails.firstName || '');
    const [lastName, setLastName] = useState(partnerDetails.lastName || '');
    const [date, setDate] = useState(new Date());

    const signatureRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!signatureRef.current || signatureRef.current.isEmpty()) {
            alert('Please provide your signature.');
            return;
        }

        const details = {
            firstName,
            lastName,
            signatureImage: signatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
            date: date.toLocaleDateString(),
        };

        navigate('/signdocuments', { state: { clientDetails, partnerDetails: details } });
    };

    const clearSignature = () => {
        if (signatureRef.current) {
            signatureRef.current.clear();
        }
    };

    return (
        <div className="input-page partner-page">
            {/* Back Arrow Button */}
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <button
                    style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        cursor: 'pointer',
                        marginBottom: '20px',
                        padding: '10px',
                        width: '25%',
                        textAlign: 'center',
                        borderRadius: '5px',
                        marginLeft: '0',
                        minWidth: '75px',
                        maxWidth: '200px',
                    }}
                    onClick={() => navigate(-1)}
                >
                    <ArrowLeft size={24} style={{ marginRight: '8px' }} />
                    Back
                </button>
            </div>

            <form onSubmit={handleSubmit}>
                <h2>Partner's Contract Agreement</h2>
                <label>
                    First Name:
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                </label>
                <label>
                    Last Name:
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                </label>
                <label>
                    Date:
                    <DatePicker 
                        selected={date} 
                        onChange={(date) => setDate(date)} 
                        dateFormat="MM/dd/yyyy" 
                        required 
                        onSelect={() => document.activeElement.blur()} // Collapse the calendar on date select
                    />
                </label>
                <label>
                    Signature:
                    <SignaturePad ref={signatureRef} canvasProps={{ className: 'signatureCanvas' }} />
                </label>
                
                {/* Clear Signature Button */}
                <button
                    type="button"
                    style={{
                        backgroundColor: '#6c757d', // Gray color
                        color: 'white',
                        border: 'none',
                        padding: '8px 16px',
                        fontSize: '14px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginBottom: '20px',
                        display: 'block',
                    }}
                    onClick={clearSignature}
                >
                    Clear Signature
                </button>

                <button type="submit" style={{ backgroundColor: '#28a745', color: 'white', padding: '10px 20px', fontSize: '16px', borderRadius: '5px', cursor: 'pointer' }}>
                    Submit
                </button>
            </form>
        </div>
    );
}

export default PartnerPage;
