import React from 'react';
import '../HomePage/Home.css';
import { Helmet } from 'react-helmet';
import homeoKit from '../assets/products/Homeopathic_Childbirth_Kit.png';
import peanutball from '../assets/products/peanutball.png';
import exerciseball from '../assets/products/exerciseball.png';
import peribottle from '../assets/products/peribottle.png';
import vitamin from '../assets/products/vitamin-code.png';
import calm from '../assets/products/calm.png';
import comb from '../assets/products/comb.png';
import rasberry from '../assets/products/rasberry.png';
import liquidiv from '../assets/products/liquidiv.png';
 import nettle from '../assets/products/nettle.png';
import chamomile from '../assets/products/chamomile.png';

const Resources = () => {
  return (
    <div className="home">
      <Helmet>
        <title>Perfect Love Doula Services || Catholic Doula Services in St. Louis </title>
        <meta name="description" content="Catholic Doula Service available in St. Louis" />
      </Helmet>      

      {/* New content-block for embedding links */}
      <div className="useful-item">
        <h4>Some Useful Items:</h4>
      </div>
      <div className="product-row">
        <div className="product-block">          
            <p><a target="_blank" href="https://www.amazon.com/18-Remedy-Homeopathic-Childbirth-Kit/dp/B004Z2UVLM/ref=sr_1_5_pp?crid=1271EGYIOF3IE&keywords=homeopathy+birth+kit&qid=1708712361&sprefix=homeopathy+birth+kit%2Caps%2C101&sr=8-5&linkCode=ur2&tag=perfectlovedo-20&linkId=85151d1b2aebe816a2cd80b5a3c9abea&camp=1789&creative=9325">
              <img src={homeoKit} alt="" />
              Homeopathy Childbirth Kit</a></p>         
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/Peanut-Ball-Exercise-Birthing-Physical/dp/B07ZCT7VDY?crid=QZSWS89HN2DL&keywords=peanut%2Bball&qid=1708719469&sprefix=peanut%2Bball%2Caps%2C105&sr=8-7&th=1&linkCode=sl1&tag=perfectlovedo-20&linkId=ea4c284113eb98d4b26982e5a421dd9b&language=en_US&ref_=as_li_ss_tl">
            <img src={peanutball} alt="" />
            Peanut Ball</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/dp/B07R42MK5Y?pd_rd_i=B07R42MK5Y&pd_rd_w=sFNzb&pf_rd_p=a53ea610-e450-44d1-897e-68c0c718bf50&pf_rd_r=7M6HE2145QY951EWA7HQ&pd_rd_wg=mtZsP&pd_rd_r=5ec19ceb-de62-4251-8f4f-ed68a7acb3a9&linkCode=sl1&tag=perfectlovedo-20&linkId=017e36f2fb1608a73fe9a90040674b3f&language=en_US&ref_=as_li_ss_tl&th=1&psc=1">
            <img src={exerciseball} alt="" />
            Birth Ball</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/Postpartum-Portable-Perineal-Recovery-Cleansing/dp/B0BLPR9JL2?pd_rd_w=lY3Pb&pf_rd_p=93739e14-ee3f-44bc-915f-15931c8a48b3&pf_rd_r=FZ2GDH0SM2TQ1JBZXCSD&pd_rd_wg=clr7v&pd_rd_r=24bb98fb-6a0f-411f-9abe-7ac37c6a8563&pd_rd_i=B0BLPR9JL2&psc=1&linkCode=sl1&tag=perfectlovedo-20&linkId=be01492c7e0be523f428d751c686b362&language=en_US&ref_=as_li_ss_tl">
            <img src={peribottle} alt="" />
            Peri Bottle</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/Garden-Life-Iron-Supplement-Capsules/dp/B0098U0QWA?pd_rd_w=Exk3E&pf_rd_p=225b4624-972d-4629-9040-f1bf9923dd95&pf_rd_r=F52NXK59HXCPVEE8Y3RM&pd_rd_wg=6URNI&pd_rd_r=ae156675-b126-4095-b702-36db2d210d97&pd_rd_i=B0098U0QWA&linkCode=sl1&tag=perfectlovedo-20&linkId=22b0adb25830b19b18c27c5e04601b02&language=en_US&ref_=as_li_ss_tl">
            <img src={vitamin} alt="" />
            Iron Supplement</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/dp/B00BPUY3W0?amp=&crid=20XX97VMRY7A7&sprefix=natural+calm&linkCode=sl1&tag=perfectlovedo-20&linkId=de7722f2ac59929d4b2c973a96ce658e&language=en_US&ref_=as_li_ss_tl">
            <img src={calm} alt="" />
            Natural Calm Magnesium</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/GranNaturals-Wooden-Beard-Detangler-Women/dp/B075QNSCRK?crid=3GDPFLPUN4NHA&keywords=wooden+comb&qid=1708722363&sprefix=wooden+comb%2Caps%2C101&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=sl1&tag=perfectlovedo-20&linkId=0bfddf632741618470adca1bdd938ce7&language=en_US&ref_=as_li_ss_tl">
            <img src={comb} alt="" />
            Wooden Comb</a></p>
</div>
          <div className="product-block">
            <p><a target="_blank" href="https://www.amazon.com/Liquid-I-V-Multiplier-Electrolyte-Supplement/dp/B01IT9NLHW/ref=pd_gw_rpt_sd_biaws_c_8?_encoding=UTF8&pd_rd_i=B01IT9NLHW&pd_rd_w=zQkyk&pf_rd_p=ae3c55fc-7f19-4a1a-be25-ba2c1b81e6de&pf_rd_r=VETRJ96236VWP7492C8S&pd_rd_wg=iLHb5&pd_rd_r=c711ea89-9822-4a98-8505-5b2e0bcb183d">
              <img src={liquidiv} alt="" />
              Liquid IV</a></p>
          </div>
          <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/Organic-Red-Raspberry-Leaf-Resealable/dp/B01G5NKKS2?crid=2JM5MRNQFFCZ&keywords=red+raspberry+leaf+tea&qid=1708717414&sprefix=red+rasp%2Caps%2C102&sr=8-13-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9tdGY&psc=1&linkCode=sl1&tag=perfectlovedo-20&linkId=8025d1a7ede8825c984201abe16985aa&language=en_US&ref_=as_li_ss_tl">

            <img src={rasberry} alt="" />
            Red Raspberry Leaf Tea</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/Frontier-Stinging-Nettle-Leaf-Organic/dp/B008AJWSSK?crid=I5N7FGE2W4Y0&keywords=stinging+nettle+tea&qid=1708717622&sprefix=%2Caps%2C129&sr=8-9&linkCode=ur2&tag=perfectlovedo-20&linkId=91cd036c611c3d6358869aaa5a6f9825&language=en_US&ref_=as_li_ss_tl">
            <img src={nettle} alt="" />
            Stinging Nettle Leaf Tea</a></p>
        </div>
        <div className="product-block">
          <p><a target="_blank" href="https://www.amazon.com/Anthonys-Organic-Chamomile-Flowers-Irradiated/dp/B08571XXJF?crid=32HFXZZTGY86M&keywords=bulk+chamomile+tea&qid=1708717826&sprefix=bulk+ch%2Caps%2C99&sr=8-4-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=sl1&tag=perfectlovedo-20&linkId=81799d66e85161da42a63c50a3881876&language=en_US&ref_=as_li_ss_tl">
            <img src={chamomile} alt="" />
            Chamomile Flower Tea</a></p>

        </div>
      </div>
      <div class="disclaimer">
  <p>Disclosure: Some of the links on this website are affiliate links, which means that if you click on them and make a purchase, I may earn a small commission from Amazon. This commission comes at no additional cost to you and helps support my business. Thank you for your support!</p>
</div>

    </div>
  );
};

export default Resources;
