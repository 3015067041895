import React from 'react';
import './Reviews.css';

function Reviews() {
  return (
    <div className="reviews-container">
      <h1>Reviews</h1>
      <div className="reviews-grid">
        <div className="review-placeholder">
          <p>Amanda was an exceptional doula. She provided knowledge and support and created an environment where I did not have to be fearful of a first-time unmedicated birth. She was respectful of my wishes and the way I wanted to labor, asking whether I wanted to be prayerful or intuitive, and just let me decide the course and atmosphere. She was also very intuitive with providing labor support, trying what feels most comfortable and listening to mom. She arrived at the hospital (planned unmedicated hospital birth) very soon after I arrived even though I was at the outskirts of her service area! After a very quick active labor I knew I was ready to give birth and she was right there with leg support and coaching me the whole way through as we welcomed our baby girl. I absolutely recommend Amanda and will be hiring her doula services with any future children God may allow.</p>
          <span>- Sarah Costello</span>
        </div>
        <div className="review-placeholder">
          <p>Amanda served as my first doula and I had the privilege of her consistent support for my first homebirth.  I am pretty sure I could not have gotten through this birth without birth trauma had Amanda not been there every step of the way.  She was calm and supportive, educational and hands-on when needed.  
Her hip squeeze technique, in particular, was critical for me during the labor and transition stages, alleviating what could have otherwise been overwhelming pain. 
Thanks to Amanda's dedication to my comfort and her non-medical pain relief methods, I was able to have a beautiful birth experience, free from any lingering mental trauma. I believe her continuous presence, combined with my excellent homebirth team, helped me set the stage to avoid postpartum depression and enjoy my new baby.
In summary, I recommend Amanda's services to any mother seeking a compassionate and knowledgeable doula.</p>
          <span>- Jamie Adams</span>
        </div>
        <div className="review-placeholder">
          <p>Amanda was one amongst the numerous blessings during my first delivery and second. I had contacted her just two months before my due date, but she kindly offered to educate me about the delivery process. Before her classes, I was completely unaware of anything and didn’t know what to expect. In a short time, I learnt a lot which was because she was so well informed and had so much to share about pregnancy and delivery. In spite of her having a small baby at home to care for, she jumped when I asked her to come for my first delivery. Her presence and support meant a lot to me and my husband and even to my family. We prayed, laughed and experienced it all together from the beginning to the end. She helped me with my pain both physically and mentally. She helped me with my decisions and gave me a lot of courage at that time. I am so grateful to have an educated woman to support me during that time. I will always remember her lines, it’s not pain but intensity which kept me going to have two vaginal births, the second without an epidural. Thankful for her womanhood companionship and friendship forever.</p>
          <span>- Anu Francis </span>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
