import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './HomePage/Header'; // Adjust the path as necessary
import Home from './HomePage/Home';
import About from './Components/About'; // Adjust the path as necessary
import Resources from './Components/Resources'; // Adjust the path as necessary
import ServicesFAQ from './Components/ServicesFAQ'; // Adjust the path as necessary
import Reviews from './Components/Reviews'; // Adjust the path as necessary
import Contact from './Components/Contact'; // Adjust the path as necessary
import SignDocuments from './Components/SignDocuments'; // Adjust the path as necessary
import ClientPage from './Components/ClientPage'; // Adjust the path as necessary
import PartnerPage from './Components/PartnerPage'; 
import './App.css';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div>
      <Helmet>
        <title>Perfect Love Doula Services || Catholic Doula Services in St. Louis</title>
        <meta name="description" content="Catholic Doula Service available in St. Louis" />
      </Helmet>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/servicesfaq" element={<ServicesFAQ />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signdocuments" element={<SignDocuments />} />
          <Route path="/input/client" element={<ClientPage />} />
          <Route path="/input/partner" element={<PartnerPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
