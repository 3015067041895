import React, { useState, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useLocation } from 'react-router-dom';
import './InputPage.css';
import { ArrowLeft } from 'react-bootstrap-icons';
import { TouchableOpacity, Text, View } from 'react-native'; // Import from react-native-web

function ClientPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { clientDetails = {}, partnerDetails = {} } = location.state || {};

    const [firstName, setFirstName] = useState(clientDetails.firstName || '');
    const [lastName, setLastName] = useState(clientDetails.lastName || '');
    const [date, setDate] = useState(null); // Removed default value
    const [depositDate, setDepositDate] = useState(null); // Removed default value
    const [installment1, setInstallment1] = useState(null); // Removed default value
    const [installment2, setInstallment2] = useState(null); // Removed default value
    const [permissionPhotos, setPermissionPhotos] = useState(clientDetails.permissionPhotos || 'GIVE');
    const [allowMarketing, setAllowMarketing] = useState(clientDetails.allowMarketing || 'WILL');

    const signatureRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!firstName || !lastName || !date || !depositDate || !installment1 || !installment2) {
            alert('All fields are required.');
            return;
        }

        if (!signatureRef.current || signatureRef.current.isEmpty()) {
            alert('Please provide your signature.');
            return;
        }

        setIsSubmitting(true);

        const details = {
            firstName,
            lastName,
            signatureImage: signatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
            date: date.toLocaleDateString(),
            depositDate: depositDate.toLocaleDateString(),
            installment1: installment1.toLocaleDateString(),
            installment2: installment2.toLocaleDateString(),
            permissionPhotos,
            allowMarketing,
        };

        setTimeout(() => {
            navigate('/signdocuments', { state: { clientDetails: details, partnerDetails } });
        }, 300);
    };

    const clearSignature = () => {
        if (signatureRef.current) {
            signatureRef.current.clear();
        }
    };

    return (
        <View style={{ flex: 1, padding: 20, backgroundColor: '#f5f5f5' }}>
            {/* Back Arrow Button */}
            <TouchableOpacity
                style={{
                    backgroundColor: '#007bff',
                    padding: 10,
                    borderRadius: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '25%',
                    minWidth: 75,
                    maxWidth: 200,
                    marginBottom: 20,
                }}
                onPress={() => navigate(-1)}
            >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <ArrowLeft size={24} color="white" style={{ marginRight: 8 }} />
                    <Text style={{ color: 'white', fontSize: 16 }}>Back</Text>
                </View>
            </TouchableOpacity>

            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <h2>Client's Contract Agreement</h2>

                <label>
                    First Name:
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                    />
                </label>
                <label>
                    Last Name:
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                    />
                </label>
                <label>
                    Date:
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="MM/dd/yyyy"
                        required
                        className="custom-datepicker"
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decreaseMonth();
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    {'<'}
                                </button>
                                <span>{new Date(date).toLocaleString('default', { month: 'long' })} {new Date(date).getFullYear()}</span>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        increaseMonth();
                                    }}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {'>'}
                                </button>
                            </div>
                        )}
                    />
                </label>
                <label>
                    Deposit Date:
                    <DatePicker
                        selected={depositDate}
                        onChange={(date) => setDepositDate(date)}
                        dateFormat="MM/dd/yyyy"
                        required
                        className="custom-datepicker"
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decreaseMonth();
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    {'<'}
                                </button>
                                <span>{new Date(date).toLocaleString('default', { month: 'long' })} {new Date(date).getFullYear()}</span>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        increaseMonth();
                                    }}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {'>'}
                                </button>
                            </div>
                        )}
                    />
                </label>
                <label>
                    Installment 1:
                    <DatePicker
                        selected={installment1}
                        onChange={(date) => setInstallment1(date)}
                        dateFormat="MM/dd/yyyy"
                        required
                        className="custom-datepicker"
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decreaseMonth();
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    {'<'}
                                </button>
                                <span>{new Date(date).toLocaleString('default', { month: 'long' })} {new Date(date).getFullYear()}</span>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        increaseMonth();
                                    }}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {'>'}
                                </button>
                            </div>
                        )}
                    />
                </label>
                <label>
                    Installment 2:
                    <DatePicker
                        selected={installment2}
                        onChange={(date) => setInstallment2(date)}
                        dateFormat="MM/dd/yyyy"
                        required
                        className="custom-datepicker"
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decreaseMonth();
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    {'<'}
                                </button>
                                <span>{new Date(date).toLocaleString('default', { month: 'long' })} {new Date(date).getFullYear()}</span>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        increaseMonth();
                                    }}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {'>'}
                                </button>
                            </div>
                        )}
                    />
                </label>
                <label>
                    Permission to take photos and videos?
                    <select
                        value={permissionPhotos}
                        onChange={(e) => setPermissionPhotos(e.target.value)}
                        required
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                    >
                        <option value="GIVE">GIVE</option>
                        <option value="DO NOT GIVE">DO NOT GIVE</option>
                    </select>
                </label>
                <label>
                    Allow my doula to share these photos and videos for both social media and marketing purposes?
                    <select
                        value={allowMarketing}
                        onChange={(e) => setAllowMarketing(e.target.value)}
                        required
                        style={{ width: '100%', padding: 10, marginVertical: 5 }}
                    >
                        <option value="WILL">WILL</option>
                        <option value="WILL NOT">WILL NOT</option>
                    </select>
                </label>
                <label>
                    Signature:
                    <SignaturePad ref={signatureRef} canvasProps={{ className: 'signatureCanvas' }} />
                </label>

                {/* Clear Signature Button */}
                <TouchableOpacity
                    style={{
                        backgroundColor: '#6c757d',
                        padding: 12,
                        borderRadius: 5,
                        marginBottom: 20,
                        alignItems: 'center',
                    }}
                    onPress={clearSignature}
                >
                    <Text style={{ color: 'white', fontSize: 14 }}>Clear Signature</Text>
                </TouchableOpacity>

                {/* Submit Button */}
                <TouchableOpacity
                    style={{
                        backgroundColor: isSubmitting ? '#218838' : '#28a745',
                        padding: 12,
                        borderRadius: 5,
                        alignItems: 'center',
                        opacity: isSubmitting ? 0.7 : 1,
                        transition: 'opacity 0.3s ease',
                    }}
                    onPress={handleSubmit}
                    disabled={isSubmitting}
                >
                    <Text style={{ color: 'white', fontSize: 16 }}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Text>
                </TouchableOpacity>
            </form>
        </View>
    );
}

export default ClientPage;
