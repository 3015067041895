import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Image } from 'react-bootstrap';
import banner from '../assets/img/banner.png'; // Adjust the path as necessary
import { Helmet } from 'react-helmet';

const reviews = [
  {
    text: "Amanda was an exceptional doula. She provided knowledge and support and created an environment where I did not have to be fearful of a first-time unmedicated birth... ",
    client: "Sarah Costello"
  },
  {
    text: "I am pretty sure I could not have gotten through this birth without birth trauma had Amanda not been there every step of the way. She was calm and supportive, educational and hands-on when needed...",
    client: "Jamie Adams"
  },
  {
    text: "Amanda was one amongst the numerous blessings during my first delivery and second. I had contacted her just two months before my due date, but she kindly offered to educate me about the delivery process...",
    client: "Anu Francis"
  }
];

const Home = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [previousReviewIndex, setPreviousReviewIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setPreviousReviewIndex(currentReviewIndex);
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 8000); // Change review every 8 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [currentReviewIndex]);

  return (
    <div className="home">
      <Helmet>
        <title>Perfect Love Doula Services || Catholic Doula Services in St. Louis</title>
        <meta name="description" content="Catholic Doula Service available in St. Louis" />
      </Helmet>
      <div className="banner">
        <Image src={banner} fluid alt="Banner" />
      </div>
      <div className="content-row-text">
        <div className="content-block-text">
          <p>The labor room is a chapel, the birthing bed an altar, and the laboring woman is an offering of love bringing forth new life into the world.</p>
          <p>As a woman’s soul is transformed through the physical process of birth, Perfect Love Doulas aim to assist the laboring woman to engage her body, mind, and soul in the transformation.</p>
        </div>
        <div className="content-block-text">
          <p>Perfect Love Doula Services understands that birth, especially when approached in love and wonder, is a transformative and sacred moment in the life of a woman.</p>
          <p>I am here to support your pregnancy and childbirth journey. As an experienced doula, I provide caring and professional assistance. Feel free to get in touch with me for any questions or inquiries.</p>
        </div>
      </div>

      {/* Cascading Reviews */}
      <div className="reviews-section">
        {reviews.map((review, index) => (
          <div
            key={index}
            className={`review-box ${
              index === currentReviewIndex
                ? 'show'
                : index === previousReviewIndex
                ? 'hide'
                : ''
            }`}
          >
            <p>"{review.text}"</p>
            <span>- {review.client}</span>
            <div className="review-link">
              <Link to="/reviews">Read Full Reviews</Link>
            </div>
          </div>
        ))}
      </div>

      <div className="contact-info">
        <a href="https://www.facebook.com/profile.php?id=100066670148374" target="_blank" rel="noopener noreferrer">
          Contact Me {"   "}
        </a>
        <a href="https://www.facebook.com/profile.php?id=100066670148374" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <br /><br />
        <a href="mailto:doula@perfectlovedoula.com">
          Email: doula@perfectlovedoula.com
        </a>
      </div>

      <div className="map-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1592955.72253719!2d-91.59782410320742!3d38.75816127634836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df333ece73e09d%3A0xbae7aa3e7b366814!2sSt.%20Louis%20Metropolitan%20Area!5e0!3m2!1sen!2sus!4v1709060149572!5m2!1sen!2sus" 
          width="640" 
          height="480" 
          style={{ border: 0 }} 
          allowFullScreen="" 
          loading="lazy">
        </iframe>
      </div>
    </div>
  );
};

export default Home;
