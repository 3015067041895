import React, { useState, useEffect, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useNavigate, useLocation } from 'react-router-dom';
import './SignDocuments.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};

async function extractTextPositions(arrayBuffer) {
    try {
        const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
        const pdf = await loadingTask.promise;
        const positions = [];

        for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const textContent = await page.getTextContent();

            textContent.items.forEach(item => {
                const text = item.str.trim();

                if (text.includes('Deposit date:')) {
                    positions.push({ text: 'Deposit Date', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Installment 1:')) {
                    positions.push({ text: 'Installment 1', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Installment 2:')) {
                    positions.push({ text: 'Installment 2', x: item.transform[4], y: item.transform[5], page: i + 1 });
                }

                if (text === 'I,') {
                    positions.push({ text: 'I,', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('(client)')) {
                    positions.push({ text: '(client)', x: item.transform[4], y: item.transform[5], page: i + 1 });
                }

                if (text.includes('Amanda L. Antony (doula) on') && i + 1 === 6) {
                    positions.push({ text: 'Contract Date Embed', x: item.transform[4], y: item.transform[5], page: i + 1 });
                }

                if (text.includes('Client Name')) {
                    positions.push({ text: 'Client Name', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Client Signature')) {
                    positions.push({ text: 'Client Signature', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Partner Name')) {
                    positions.push({ text: 'Partner Name', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Partner Signature')) {
                    positions.push({ text: 'Partner Signature', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Doula Signature')) {
                    positions.push({ text: 'Doula Signature', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('Date')) {
                    const lastPosition = positions[positions.length - 1];
                    if (lastPosition && lastPosition.text.includes('Client Signature')) {
                        positions.push({ text: 'Client Date', x: item.transform[4], y: item.transform[5], page: i + 1 });
                    } else if (lastPosition && lastPosition.text.includes('Partner Signature')) {
                        positions.push({ text: 'Partner Date', x: item.transform[4], y: item.transform[5], page: i + 1 });
                    } else if (lastPosition && lastPosition.text.includes('Doula Signature')) {
                        positions.push({ text: 'Doula Date', x: item.transform[4], y: item.transform[5], page: i + 1 });
                    }
                } else if (text.includes('[choose an option: GIVE / DO NOT GIVE]')) {
                    positions.push({ text: 'Permission Photos Option', x: item.transform[4], y: item.transform[5], page: i + 1 });
                } else if (text.includes('[choose an option: WILL / WILL NOT]')) {
                    positions.push({ text: 'Allow Marketing Option', x: item.transform[4], y: item.transform[5], page: i + 1 });
                }
            });
        }
        return positions;
    } catch (error) {
        console.error('Error extracting text positions:', error);
        return [];
    }
}

async function addFormFields(arrayBuffer, positions, clientDetails, partnerDetails) {
    try {
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const pages = pdfDoc.getPages();

        let clientNameEmbedded = false;
        let contractDateEmbedded = false;

        positions.forEach(async (pos) => {
            const page = pages[pos.page - 1];
            let text = '';
            let image = null;

            if (pos.text === 'Client Name') {
                text = `${clientDetails?.firstName || ''} ${clientDetails?.lastName || ''}`;
            } else if (pos.text === 'Client Signature') {
                image = clientDetails?.signatureImage;
            } else if (pos.text === 'Partner Name') {
                text = `${partnerDetails?.firstName || ''} ${partnerDetails?.lastName || ''}`;
            } else if (pos.text === 'Partner Signature') {
                image = partnerDetails?.signatureImage;
            } else if (pos.text === 'Client Date') {
                text = clientDetails?.date || ' ';
            } else if (pos.text === 'Partner Date') {
                text = partnerDetails?.date || ' ';
            } else if (pos.text === 'Deposit Date') {
                text = clientDetails?.depositDate || ' ';
            } else if (pos.text === 'Installment 1') {
                text = clientDetails?.installment1 || ' ';
            } else if (pos.text === 'Installment 2') {
                text = clientDetails?.installment2 || ' ';
            } else if (pos.text === 'I,') {
                const clientName = `${clientDetails?.firstName || ''} ${clientDetails?.lastName || ''}`;

                if (!clientNameEmbedded) {
                    const xAfterI = pos.x + 10 + 28.35; // 10 mm to the right of "I,"
                    page.drawText(clientName, { x: xAfterI, y: pos.y, size: 12 });
                    clientNameEmbedded = true;
                }
            } else if (pos.text === 'Contract Date Embed' && pos.page === 6) {
                if (!contractDateEmbedded) {
                    const contractDate = clientDetails?.date || '';
                    const pageWidth = page.getWidth();
                    const xRightAligned = pageWidth - 28.35 - 20; // Original right alignment with 20 mm from the right
                    const adjustedX = xRightAligned - 56.7 - 19.845; // Move an additional 7 mm to the left

                    page.drawText(contractDate, { x: adjustedX, y: pos.y, size: 12 });
                    contractDateEmbedded = true;
                }
            } else if (pos.text === 'Permission Photos Option') {
                const xAdjusted = pos.x - 85.05; // Move 30 mm to the left of the option text
                const permissionText = clientDetails?.permissionPhotos || '';
                page.drawText(permissionText, { x: xAdjusted, y: pos.y, size: 12 });
            } else if (pos.text === 'Allow Marketing Option') {
                const xAdjusted = pos.x - 85.05; // Move 30 mm to the left of the option text
                const marketingText = clientDetails?.allowMarketing || '';
                page.drawText(marketingText, { x: xAdjusted, y: pos.y, size: 12 });
            }

            // Handle text embedding
            if (text && pos.text !== 'I,' && pos.text !== 'Contract Date Embed') {
                page.drawText(text, { x: pos.x + 100, y: pos.y + 2.835, size: 12 });
            }

            // Handle image embedding
            if (image) {
                const signatureImage = await pdfDoc.embedPng(image);
                page.drawImage(signatureImage, {
                    x: pos.x + 100,
                    y: pos.y - 30 + 28.35, // Move 10mm (28.35 points) above current position
                    width: 150 * 0.35, // Resize width to 35%
                    height: 50 * 0.35  // Resize height to 35%
                });
            }
        });

        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
    } catch (error) {
        console.error('Error adding form fields:', error);
        return null;
    }
}

function SignDocuments() {
    const [pdfBytes, setPdfBytes] = useState(null);
    const [pdfLoadError, setPdfLoadError] = useState(false); // Track if PDF fails to load
    const location = useLocation();
    const navigate = useNavigate();
    const { clientDetails, partnerDetails } = location.state || {};

    const pdfFileLocation = '/Birth_Doula_Contract_2024.pdf'; // Ensure this is in your public folder

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const response = await fetch(pdfFileLocation);
                if (!response.ok) {
                    throw new Error(`Failed to load PDF: ${response.statusText}`);
                }
                const arrayBuffer = await response.arrayBuffer();
                const textPositions = await extractTextPositions(arrayBuffer);
                const initialPdfBytes = await addFormFields(arrayBuffer, textPositions, clientDetails || {}, partnerDetails || {});
                setPdfBytes(initialPdfBytes);
            } catch (error) {
                console.error('Error loading PDF:', error);
                setPdfLoadError(true); // Set error state if PDF fails to load
            }
        };

        loadPdf();
    }, [clientDetails, partnerDetails]);

    const handleSavePdf = async () => {
        try {
            const response = await fetch(pdfFileLocation);
            const arrayBuffer = await response.arrayBuffer();
            const textPositions = await extractTextPositions(arrayBuffer);
            const updatedPdfBytes = await addFormFields(arrayBuffer, textPositions, clientDetails, partnerDetails);
            setPdfBytes(updatedPdfBytes);

            const blob = new Blob([updatedPdfBytes], { type: 'application/pdf' });
            saveAs(blob, 'Signed_Contract.pdf');
        } catch (error) {
            console.error('Error saving PDF:', error);
        }
    };

    const openInputPage = (formType) => {
        navigate(`/input/${formType}`, { state: { clientDetails, partnerDetails } });
    };

    // Define the PdfViewer component inside SignDocuments
    const PdfViewer = ({ pdfBytes }) => {
        const canvasRefs = useRef([]);

        useEffect(() => {
            const renderPDF = async () => {
                try {
                    const loadingTask = pdfjsLib.getDocument({ data: pdfBytes });
                    const pdf = await loadingTask.promise;

                    // Create a canvas for each page
                    canvasRefs.current = [];
                    for (let i = 0; i < pdf.numPages; i++) {
                        const page = await pdf.getPage(i + 1);
                        const viewport = page.getViewport({ scale: window.innerWidth / 600 }); // Adjust scale dynamically
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        canvas.width = viewport.width;
                        canvas.height = viewport.height;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        await page.render(renderContext);
                        canvasRefs.current.push(canvas);
                    }

                    // Append all canvases to the container
                    const container = document.getElementById('pdf-canvas-container');
                    container.innerHTML = ''; // Clear previous content
                    canvasRefs.current.forEach(canvas => {
                        container.appendChild(canvas);
                    });
                } catch (error) {
                    console.error('Error rendering PDF:', error);
                    setPdfLoadError(true); // Set error state if rendering fails
                }
            };

            if (pdfBytes) {
                renderPDF();
            }
        }, [pdfBytes]);

        return (
            <div id="pdf-canvas-container" style={{ overflowY: 'auto', maxHeight: '100vh' }}>
                {/* Canvases will be appended here */}
            </div>
        );
    };

    return (
        <div className="sign-documents">
            {/* Display messages at the top */}
            {clientDetails && <p>Client details saved.</p>}
            {partnerDetails && <p>Partner details saved.</p>}

            {/* Buttons for input forms */}
            <div className="button-group">
                <button className="btn btn-custom" onClick={() => openInputPage('client')}>
                    Client's Contract Agreement and Signature
                </button>
                <button className="btn btn-custom" onClick={() => openInputPage('partner')}>
                    Partner's Contract Agreement and Signature
                </button>
            </div>

            {/* Always show the download link */}
            <div>
                <p>Download unsigned document:</p>
                <a href={pdfFileLocation} download>Download PDF</a>
            </div>

            {/* PDF Viewer or fallback */}
            {pdfLoadError ? (
                <div>
                    <p>Unable to view the PDF. Please download the PDF to view it in another viewer.</p>
                </div>
            ) : (
                pdfBytes && (
                    <>
                        <p>Please read the entire contract of services and click the options above to digitally sign the contract.</p>

                        <div className="pdf-viewer-container">
                            {isMobileDevice() ? (
                                <PdfViewer pdfBytes={pdfBytes} />
                            ) : (
                                <iframe
                                    src={URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }))}
                                    title="PDF Viewer"
                                    className="pdf-viewer"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                        overflow: 'auto' // Allow scrolling inside the iframe
                                    }}
                                />
                            )}
                        </div>

                        <div>
    <button
        onClick={handleSavePdf}
        style={{
            backgroundColor: '#28a745', // Green Bootstrap color
            color: 'white',
            border: 'none',
            padding: '12px 20px',
            fontSize: '16px',
            borderRadius: '5px', // Rounded corners
            cursor: 'pointer',
            textAlign: 'center',
            width: '100%', // Full width on smaller screens, adjust as needed
            maxWidth: '300px', // Limit max width on larger screens
            margin: '20px auto', // Center align with margin
            display: 'block', // Ensure it is a block-level element for center alignment
            transition: 'background-color 0.3s ease, opacity 0.3s ease', // Smooth hover and active effects
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = '#4E9FF2')} 
        onMouseOut={(e) => (e.target.style.backgroundColor = '#28a745')}
        onMouseDown={(e) => {
            e.target.style.backgroundColor = '#218838';
            e.target.style.opacity = '0.7';
        }}
        onMouseUp={(e) => {
            e.target.style.backgroundColor = '#28a745';
            e.target.style.opacity = '1';
        }}
        onTouchStart={(e) => {
            e.target.style.backgroundColor = '#218838';
            e.target.style.opacity = '0.7';
        }}
        onTouchEnd={(e) => {
            e.target.style.backgroundColor = '#28a745';
            e.target.style.opacity = '1';
        }}
    >
        Download Final Signed PDF
    </button>
</div>

                    </>
                )
            )}
        </div>
    );
}

export default SignDocuments;
