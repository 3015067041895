import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import '../HomePage/Home.css';
import './Contact.css';

function Contact() {
  return (
    <div className="home">
      <p>
        Serving the Saint Louis metro area. Contact me for current availability.
      </p>
      
      {/* Google Form */}
      <div className="iframe-container">
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSe98EU8GCQpJW7JeonC8XbmrvSLF6L3P7qVwOZvl-dmD7GY_A/viewform?embedded=true" 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          title="Feedback Form"
          allowFullScreen
          loading="lazy">
          Loading…
        </iframe>
      </div>
      
      {/* Calendar Schedule */}
      <div className="iframe-container">
        <iframe 
          src="https://cal.com/perfectlovedoula/freeconsult?user=perfectlovedoula&month=2024-08&date=2024-08-19" 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          title="Schedule a Consultation"
          style={{ width: '100%', height: '600px', border: 'none' }}
          allowFullScreen
          loading="lazy">
        </iframe>
      </div>

      {/* Google Map */}
      <div className="iframe-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1592955.72253719!2d-91.59782410320742!3d38.75816127634836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df333ece73e09d%3A0xbae7aa3e7b366814!2sSt.%20Louis%20Metropolitan%20Area!5e0!3m2!1sen!2sus!4v1709060149572!5m2!1sen!2sus" 
          style={{ width: '100%', height: '480px', border: 'none' }} 
          allowFullScreen 
          loading="lazy">
        </iframe>
      </div>

      {/* Contact Info */}
      <div className="contact-info">
        <a href="https://www.facebook.com/profile.php?id=100066670148374" target="_blank" rel="noopener noreferrer">
          Contact Me {"   "}
        </a>
        <a href="https://www.facebook.com/profile.php?id=100066670148374" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <br /><br />
        <a href="mailto:doula@perfectlovedoula.com">
          Email: doula@perfectlovedoula.com
        </a>
      </div>
    </div>
  );
}

export default Contact;
